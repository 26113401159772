<template>
    <div>

        <!-- Splash qdo processando o arquivo -->
        <ScreenSplash :wrapper="wrapper"/>

        <!-- Início principal -->
        <v-card>
            <v-toolbar
                flat
            >
                <v-icon>
                    fas fa-file-invoice
                </v-icon>
                <v-toolbar-title
                    class="pl-4"
                >
                    Importação Arquivos Proceda
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon @click="fechar">
                    <v-icon>fas fa-times</v-icon>
                </v-app-bar-nav-icon>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <!-- <v-row>
                    <v-col
                        class="px-6 py-6"
                    >
                        <input type="file" @change="selectFile($event)" @click="(carrega = 0)" ref="arquivo">
                    </v-col>
                    <v-col>
                        <div :style="{visibility: loading ? 'visible' : 'hidden'}">
                            <v-progress-circular
                                :rotate="-90"
                                :size="100"
                                :width="15"
                                :value="carrega"
                                color="primary"
                            >
                                {{ carrega }}
                            </v-progress-circular>
                        </div>
                    </v-col>
                </v-row> -->

                <v-row>
                    <v-col class="px-6 py-6" >
                        <input type="file" @change="selectFile($event)" ref="arquivo">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="12"
                        md="12"
                    >
                    <v-textarea
                        outlined
                        filled
                        v-model="message"
                        :label="label"
                        :readonly="true"
                        rows="12"
                        cols="148"
                    ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <!-- Fim principal -->
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess } from '@/global'
import axios from 'axios'
import ScreenSplash from '../components/ScreenSplash.vue'

export default {
    name: 'ImportacaoProceda',
    components: {
        ScreenSplash
    },
    data: () => ({
        arquivo: '',
        message: [],
        loading: false,
        wrapper: false,

        label: 'Nenhum arquivo processado.',
        carrega: 0,
        return: {
            carrega: 0,
        }
    }),
    methods: {
        fechar () {
            this.$router.push('/')
        },

        async SendFile(url, data) {
            // var config = {
            //     onUploadProgress: function(progressEvent) {
            //         this.carrega = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            //     }.bind(this)
            // };
            await axios
                .post(url, data)
                .then(res => {
                    if (res.data.retorno) {
                        this.message = "\n" + res.data.mensagem;
                        this.message += "\n" + res.data.data;
                        showSuccess(res.data.mensagem)
                    } else {
                        this.message = "\nError\n"
                        this.message += res.data.mensagem;
                        showError(res.data.mensagem)
                    }
                })
                .catch(error => {
                    console.log("Error ", error)
                    showError(error)
                })
                .finally(() => {
                    this.$refs.arquivo.value=null;
                    this.loading = false;
                    this.wrapper = false;
                })
        },

        selectFile(event) {
            this.message = ''
            this.carrega = 0
            let file = event.target.files[0]
            this.label = "Arquivo processado:- " + file.name
            const data = new FormData()
            data.append('arquivo', file)
            data.append('cod_empresa', `${localStorage.getItem('factory')}` )
            let url = `${baseApiUrl}/import-proceda`
            this.loading = false
            this.carrega = 0
            this.wrapper = true

            this.SendFile(url, data )

        }

        // selectFile(event) {
        //     this.carrega = 0;
        //     let file = event.target.files[0];
        //     let config = { header : { 'Content-Type' : 'multipart/form-data' },
        //                     onUploadProgress: function(progressEvent) {
        //                         this.carrega = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        //                     }.bind(this)
        //     }
        //     let data = new FormData()
        //     data.append('arquivo', file)
        //     data.append('cod_empresa', `${localStorage.getItem('factory')}` )
        //     this.wrapper = true;

        //     let url = `${baseApiUrl}/import-proceda`
        //     axios.post(url, data, config)
        //         .then(res => {
        //             if (res.data.retorno) {
        //                 this.label = "Arquivo processado:- " + file.name
        //                 this.message = "\n" + res.data.data;
        //                 showSuccess(res.data.mensagem)
        //             } else {
        //                 this.message = "\nError\n"
        //                 this.message += res.data.mensagem;
        //                 showError(res.data.mensagem)
        //             }
        //         })
        //         .catch(error => {
        //             showError(error)
        //         })
        //         .finally(() => {
        //             this.$refs.arquivo.value=null;
        //             this.loading = false;
        //             this.wrapper = false,
        //             this.carrega = 0;
        //         })
        // }
    }
}
</script>